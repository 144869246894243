import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../templates/RAC'
import DynamicNotFoundPage from '../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/insights/sustainable-insights/*" />
    <DynamicNotFoundPage default />
  </Router>
)
